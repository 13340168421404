import { render, staticRenderFns } from "./index.vue?vue&type=template&id=71f59f57&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=71f59f57&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppBtn: require('/app/components/app/AppBtn.vue').default,AppIcon: require('/app/components/app/AppIcon.vue').default,AppHeading: require('/app/components/app/AppHeading.vue').default,ProgramDays: require('/app/components/program/ProgramDays.vue').default})
