import { render, staticRenderFns } from "./ProgramDays.vue?vue&type=template&id=41f1bf83&"
import script from "./ProgramDays.vue?vue&type=script&lang=js&"
export * from "./ProgramDays.vue?vue&type=script&lang=js&"
import style0 from "./ProgramDays.vue?vue&type=style&index=0&id=41f1bf83&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppSpinner: require('/app/components/app/AppSpinner.vue').default,AppTabs: require('/app/components/app/AppTabs.vue').default,AppBtn: require('/app/components/app/AppBtn.vue').default,ProgramSessions: require('/app/components/program/ProgramSessions.vue').default,AppEmpty: require('/app/components/app/AppEmpty.vue').default})
