
import Vue from "vue"
import { mapGetters } from "vuex"

export default Vue.extend({
  head () {
    return {
      title: this.getTitle(this.$t("pages.program.title")),
    }
  },

  computed: {
    ...mapGetters("app", ["getTitle"]),

    pdfs () {
      const base = `https://storage.yandexcloud.net/eventico-new/${
        this.$config.event}/program`
      const { locale } = this.$i18n
      return {
        program: `${base}/program_${locale}.pdf`,
        sessions: `${base}/sessions_${locale}.pdf`,
      }
    },
  },
})
