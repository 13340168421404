
export default {
  data: () => ({
    days: [],
    groups: [],
    sessions: [],
  }),

  async fetch () {
    await this.fetchDays()
    await this.fetchGroups()
    await this.$nextTick()
  },

  computed: {
    activeDay () {
      const activeDay = this.days.length ? this.days[0]?.alias : null
      return this.$route.query.day || activeDay
    },

    groupsWithSessions () {
      return this.groups.filter((g) => {
        return this.sessions.some(s => s.track.group.alias === g.alias)
      })
    },
  },

  watch: {
    activeDay () {
      this.$route.query.hall = undefined
    },
  },

  methods: {
    async fetchDays () {
      const [res, err] = await this.$api.program.getDays()
      if (err) console.error(err)
      else this.days = this.$api.helpers.getList(res)
      if (this.days.length) {
        const day = this.activeDay || this.days[0]?.alias
        if (day) await this.fetchSessions(day)
      }
    },

    async fetchGroups () {
      const [res, err] = await this.$api.program.getGroups()
      if (err) console.error(err)
      else this.groups = this.$api.helpers.getList(res)
    },

    async fetchSessions (day) {
      const [res, err] = await this.$api.program.getSessions({
        params: { "filter[day]": day },
      })
      if (err) console.error(err)
      else this.sessions = this.$api.helpers.getList(res)
    },
  },
}
