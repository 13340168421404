// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eJtcpGnM{align-items:center;display:flex;gap:max(.84rem,min(1.25vw,1.5rem)) max(1.13rem,min(1.67vw,2rem));justify-content:space-between;padding:var(--spacing-md) 0;position:relative}@media(max-width:667.98px){.eJtcpGnM{flex-wrap:wrap;padding:var(--spacing-sm) 0}}.OZR20FLK{color:var(--color-bg-invert);font-size:2.5rem;font-weight:700;line-height:1.47}@media(max-width:667.98px){.OZR20FLK{font-size:1.5rem}}.F0QyOIg2{align-items:center;display:flex}.F0QyOIg2>*+*{margin-left:2em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"header": "eJtcpGnM",
	"title": "OZR20FLK",
	"titleWrapper": "F0QyOIg2"
};
module.exports = ___CSS_LOADER_EXPORT___;
